@font-face {
  font-family: 'MuseoSansRounded';
  src: url('fonts/MuseoSansRounded-700.eot');
  src: url('fonts/MuseoSansRounded-700.woff2') format('woff2'),
       url('fonts/MuseoSansRounded-700.woff') format('woff'),
       url('fonts/MuseoSansRounded-700.ttf') format('truetype'),
       url('fonts/MuseoSansRounded-700.svg#MuseoSansRounded-700') format('svg'),
       url('fonts/MuseoSansRounded-700.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSansRounded';
  src: url('fonts/MuseoSansRounded-900.eot');
  src: url('fonts/MuseoSansRounded-900.woff2') format('woff2'),
       url('fonts/MuseoSansRounded-900.woff') format('woff'),
       url('fonts/MuseoSansRounded-900.ttf') format('truetype'),
       url('fonts/MuseoSansRounded-900.svg#MuseoSansRounded-900') format('svg'),
       url('fonts/MuseoSansRounded-900.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'HelveticaNeue', sans-serif;
}

*, ::before, ::after {
  box-sizing: border-box;
}

@media (min-width: 992px) {
  *:focus {
    outline: none;
  }
}

h1, h2, h3 {
  font-family: 'MuseoSansRounded';
  font-weight: 900;
}
